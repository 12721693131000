import { fixedNumberCopy } from '@/utils/number'

const mixinPositionInfo = {
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    // 1 столбец
    // Диаметр наружный
    textDOuter () {
      return (this.isType127 && this.isShape) || this.isType7 ? [this.position.d_outer_1, this.position.d_outer_2] : this.position.d_outer_1
    },
    // Опорные витки
    textReferenceTurns () {
      return [this.position.reference_turn_1?.name, this.position.reference_turn_2?.name]
    },
    // Число рабочих витков (n)/полных витков (n1)
    textNumberTurnsAndFullTurns () {
      return [this.position.number_turns, this.position.number_full_turns]
    },

    // 2 столбец
    // Направление навивки
    labelWindingDir () {
      if (this.isType35) return '/Угол м-у отводами'
      if (this.isType9) return '/Ориентация отводов'
      return ''
    },
    textWindingDir () {
      const array = [this.position.winding_dir?.name]
      if (this.isType35) {
        array.push(this.position.tap_angle)
      }
      if (this.isType9) {
        array.push(this.position.tap_orientation)
      }
      return array
    },
    // Допуск Диаметр наружный
    textDOuterLimit () {
      return (this.isType127 && this.isShape) || this.isType7 ? [this.position.d_outer_1_limit, this.position.d_outer_2_limit] : this.position.d_outer_1_limit
    },
    // Зазоры зацепа
    textHookGap () {
      return [this.position.hook_gap_1, this.position.hook_gap_2]
    },

    // 3 столбец
    // Длина развертки/масса
    textSweepLengthAndWeight () {
      return [this.position.sweep_length, fixedNumberCopy(+this.position.weight, 5)]
    },
    // Покрытие/цвет
    textCoatingAndColor () {
      return [this.position.coating?.name, this.position.color?.name || this.position.color]
    },
    isShape () {
      if (!this.position.shape) return false
      else if (this.position.shape?.id === 1) return false
      else return true
    }
  }
}

export {
  mixinPositionInfo
}
