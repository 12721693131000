<template>
  <div>
    <div class="position position-info mb-20">
        <div class="position__item">
            <InfoText
                label="Вид пружины"
                :text="position.shape ? position.shape.name : '-'"
            />
        </div>
    </div>

    <div class="position position-info">

      <!--1 строка-->
      <div class="position__item">
        <InfoText
          label="Материал"
          :text="position.material && position.material.name"
        />
      </div>

      <!--2 строка-->
      <div class="position__item">
        <InfoText
            label="d проволоки"
            :text="position.d_wire"
        />
      </div>

      <!--3 строка-->
      <div class="position__item">
        <InfoText
            v-if="visibleDInner"
            label="D внутренний"
            :text="position.d_inner"
        />
        <InfoText
          v-if="visibleDInner"
          label="Допуск по D внутр."
          :text="position.d_inner_limit"
        />

        <InfoText
            v-if="visibleDOutput"
            :label="`D наружный${isType127 && isShape ? ' 1/2' : ''}`"
            :text="textDOuter"
        />
        <InfoText
          v-if="visibleDOutput"
          :label="`Допуск по D наруж.${isType127 && isShape ? ' 1/2' : ''}`"
          :text="textDOuterLimit"
        />

        <InfoText
            v-if="isType4"
            label="Габаритная длина изделия"
            :text="position.overall_length"
        />
        <InfoText
          v-if="isType4"
          label="Допуск на линейные размеры"
          :text="position.linear_size_limit"
        />
      </div>

      <!--4 строка-->
      <div class="position__item">
        <InfoText
          v-if="notType4"
          label="Индекс"
          :text="position.index"
        />

        <InfoText
          v-if="isType127 && isShape"
          label="Индекс 2"
          :text="position.index_2"
        />
      </div>

      <!--5 строка-->
      <div class="position__item">
        <InfoText
          v-if="notType48"
          label="Направление навивки"
          :text="position.winding_dir.name"
        />
      </div>

      <!--6 строка-->
      <div class="position__item">
        <InfoText
            v-if="notType48"
            label="Длина тела (L0)"
            :text="position.body_length"
        />

        <InfoText
          v-if="notType48 && notType2"
          label="Допуск по длине"
          :text="position.body_length_limit"
        />

        <InfoText
            v-if="isType8"
            label="Зазор"
            :text="position.gap"
        />
      </div>

      <!--7 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType17"
          label="Опорные витки 1/2"
          :text="textReferenceTurns"
        />
      </div>

      <div class="position__item">
        <InfoText
          v-if="isType7"
          label="Число витков на цилиндр"
          :text="position.number_turn_cylinder"
        />
        <InfoText
          v-if="isType7"
          label="Число витков на конус"
          :text="position.number_turn_cone"
        />
      </div>

      <!--8 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType359 || isType2"
          label="Число рабочих витков (n)"
          :text="position.number_turns"
        />

        <InfoText
          v-if="isType7 || isType8"
          label="Число полных витков (n1)"
          :text="position.number_full_turns"
        />

        <InfoText
          v-if="isType1"
          label="Число рабочих витков (n)/полных витков (n1)"
          :text="textNumberTurnsAndFullTurns"
        />

        <InfoText
          v-if="visibleTurnsLimit"
          label="Допуск по виткам"
          :text="position.turns_limit"
        />
      </div>

      <!--9 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType2"
          label="Длина по зацепам"
          :text="position.length_by_hooks"
        />
        <InfoText
          v-if="isType2"
          label="Допуск по длине"
          :text="position.body_length_limit"
        />
      </div>

      <!--10 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType2"
          label="Тип зацепа 1"
          :text="position.hook_type_1 && position.hook_type_1.name"
        />

        <InfoText
          v-if="isType2"
          label="Длина зацепа 1"
          :text="position.hook_length_1"
        />
      </div>

      <!--11 строка-->
      <div class="position__item">
        <InfoText
            v-if="isType359"
            label="Длина отвода 1"
            :text="position.tap_length_1"
        />

        <InfoText
          v-if="isType359"
          label="Допуск на длину отвода 1"
          :text="position.tap_length_1_limit"
        />

        <InfoText
            v-if="isType8"
            label="Плоскостность"
            :text="position.flatness && position.flatness.name"
        />
      </div>

      <!--12 строка-->
      <div class="position__item">
        <InfoText
            v-if="isType2"
            label="Тип зацепа 2"
            :text="position.hook_type_2 && position.hook_type_2.name"
        />

        <InfoText
          v-if="isType2"
          label="Длина зацепа 2"
          :text="position.hook_length_2"
        />

        <InfoText
            v-if="isType359"
            label="Длина отвода 2"
            :text="position.tap_length_2"
        />

        <InfoText
          v-if="isType359"
          label="Допуск на длину отвода 2"
          :text="position.tap_length_2_limit"
        />

        <InfoText
            v-if="isType8"
            label="Фаска"
            :text="position.chamfer && position.chamfer.name"
        />
      </div>

      <!--13 строка-->
      <div v-if="isType2 || isType5" class="position__item">
        <InfoText
            v-if="isType2"
            label="Ориентация зацепов"
            :text="position.hook_orientation_1 && position.hook_orientation_1.name"
        />
      </div>

      <!--14 строка-->
      <div class="position__item">
        <InfoText
            v-if="isType8"
            label="Загибы"
            :text="position.folds && position.folds.name"
        />

        <InfoText
          v-if="isType5"
          label="Длина зацепа"
          :text="position.hook_length_1"
        />
      </div>

      <!--15 строка-->
      <div class="position__item">
        <InfoText
            v-if="isType35"
            label="Тип навивки"
            :text="position.winding_type && position.winding_type.name"
        />
      </div>

      <!--16 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType35"
          label="Угол м/у отводами и зацепом"
          :text="position.tap_angle"
        />
        <InfoText
          v-if="isType9"
          label="Ориентация отводов"
          :text="position.tap_orientation"
        />
      </div>

      <!--17 строка-->
      <div class="position__item">
        <InfoText
          v-if="isType17"
          label="Шаг (t)"
          :text="position.step"
        />
        <InfoText
          v-if="isType17"
          label="Допуск на шаг"
          :text="position.step_limit"
        />
      </div>

      <!--18 строка-->
      <div v-if="isType2 || isType7" class="position__item">
        <InfoText
            v-if="isType2"
            label="Зазоры зацепа 1/2"
            :text="textHookGap"
        />

        <InfoText
            v-if="isType5"
            label="Угол загиба на зацепе"
            :text="position.hook_bend_angle"
        />
      </div>

      <!--19 строка-->
      <div class="position__item">
        <InfoText
            label="Длина развертки/масса"
            :text="textSweepLengthAndWeight"
        />
      </div>

      <!--20 строка-->
      <div class="position__item">
        <InfoText
            label="Твердость"
            :text="position.hardness"
        />
      </div>

      <!--21 строка-->
      <div class="position__item">
        <InfoText
            label="Поверхностная обработка"
            :text="position.surface_treatment && position.surface_treatment.name"
        />
      </div>

      <!--22 строка-->
      <div class="position__item">
        <InfoText
            label="Испытания"
            :text="position.tests && position.tests.name"
        />
      </div>

      <!--23 строка-->
      <div class="position__item">
        <InfoText
          v-if="visibleStiffness"
          label="Жесткость, Н/мм"
          :text="calcStiffness"
        />
      </div>

      <!--24 строка-->
      <div class="position__item">
        <InfoText
            label="Покрытие/цвет"
            :text="textCoatingAndColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'
import { mixinPositionType } from '@/mixins/views/Production/Position/mixinPositionType'
import { mixinPositionCalc } from '@/mixins/views/Production/Position/mixinPositionCalc'
import { mixinPositionInfo } from '@/mixins/views/Production/Position/mixinPositionInfo'

export default {
  name: 'PositionInfo',
  components: { InfoText },
  mixins: [
    mixinPositionType,
    mixinPositionCalc,
    mixinPositionInfo
  ]
}
</script>
