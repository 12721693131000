<template>
  <div
    class="card-comment"
    :class="cardClass"
  >
    <div v-if="!isModalCard" class="card-comment__header">
      <h3 class="fw-normal" :class="cardTitleClass">
        {{ title }}
      </h3>
    </div>

    <div class="card-comment__body">
      <div class="card-comment__body-head">
        <div class="flex">
          <svgicon
            class="card-comment__avatar mr-8"
            icon="user-avatar"
            :width="iconSize"
          />

          <p class="mr-30">
            {{ fullName(comment.user.name, comment.user.surname) }}
          </p>
        </div>

        <p>
          {{ comment.created_at | date('datetime') }}
        </p>
      </div>

      <h4 v-if="isModalCard && comment.consignment" class="mb-8">
        Партия № {{ comment.consignment.sort }}
      </h4>

      <p class="card-comment__message">{{ comment.comment }}</p>
    </div>
  </div>
</template>

<script>
import { fullName } from '@/utils/string'

export default {
  props: {
    comment: {
      type: Object,
      default: () => {}
    },
    isModalCard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cardClass () {
      const classes = []
      if (this.isModalCard) classes.push('card-comment--modal')

      switch (this.comment.type) {
        case 2: classes.push('card-comment--bg-red')
      }

      return classes
    },
    title () {
      switch (this.comment.type) {
        case 2: return 'Рекламация'
        default: return 'Комментарий'
      }
    },
    cardTitleClass () {
      switch (this.comment.type) {
        case 2: return 'color--red'
        default: return 'color--green'
      }
    },
    iconSize () {
      return this.isModalCard ? '20' : '24'
    }
  },
  methods: {
    fullName
  }
}
</script>
