<template>
  <div class="stage-card">
    <div class="stage-card__header">
      <h3 class="color--main">{{ stage.name }}</h3>

      <p>{{ stage.status }}</p>
    </div>

    <div
      v-if="visibleStageExecutors"
      class="stage-card__body"
    >
      <AppInfoTextBlock
        label-text="Ответственный"
        :text="stage.responsible.name"
        :length="15"
        :has-tooltip="!isMobile"
      />

      <AppInfoTextBlock
        label-text="Исполнитель"
        :text="stageExecutor"
      />
    </div>
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock'
import { mapState } from 'vuex'
export default {
  name: 'StageCard',
  components: { AppInfoTextBlock },
  props: {
    stage: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    visibleStageExecutors () {
      switch (this.stage?.flag_id) {
        case 5:
        case 4:
        case 3: return false
        default: return true
      }
    },

    stageExecutor () {
      return this.stage.executor?.name || 'Не назначен'
    }
  }
}
</script>
