<template>
  <div class="stage-card-list">
    <div class="stage-card-list__header" v-if="isMobile">
      <h3 @click="isMobile && collapseAccordion()">Этапы</h3>
      <VButton
        v-if="isMobile"
        block="collapse"
        :class="{ open: isOpenAccordion }"
        @click.native="collapseAccordion"
        style="z-index: 2"
        :icon="{ name: 'arrow', dir: isOpenAccordion ? '0' : '180' }"
      />
    </div>
    <AccordionCard
      :is-open="!isMobile || isOpenAccordion"
    >
      <div class="stage-card-list__wrap" @dblclick="closeAccordion">
        <StageCard
          class="stage-card-list__item"
          v-for="(item, i) in stageList"
          :key="i"
          :stage="item"
        />
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import StageCard from '@/components/production/stage/StageCard'
import { mapState } from 'vuex'
import VButton from '@/components/simple/button/VButton.vue'
import AccordionCard from '@/components/accordionList/AccordionCard.vue'
import { mixinAccordion } from '@/mixins/mixinAccordion'
export default {
  name: 'StageCardList',
  components: {
    AccordionCard,
    VButton,
    StageCard
  },
  props: {
    stageList: {
      type: Array,
      required: true
    }
  },
  mixins: [mixinAccordion],

  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    })
  }
}
</script>
