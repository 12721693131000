<template>
  <Spinner v-if="loadingStage"/>

  <div class="consignment" v-else>
    <VButtonPageBack
      class="mb-30"
      :callback="onPageBack"
    />

    <div class="card mb-40">
      <header class="card__header">
        <div class="d-flex card__header-wrap ai-c gap-xs">
          <h3 class="card__title" @click="isMobile && collapseAccordion()">
            № Партии:&nbsp;{{ consignment.sort }}
          </h3>

          <div class="cons-card__header-buttons consignment__icons">
            <div class="consignment__icon consignment__icon--red" v-if="isConsignmentReclamation">
              <svgicon
                icon="notification-error"
                class="svg-icon--notification-error svg-icon--red"
                width="22px"
              />
              Рекламация
            </div>

            <div class="consignment__icon consignment__icon--red" v-if="isScrap">
              <svgicon
                icon="util"
                class="svg-icon--user-avatar"
                width="22px"
              />
              Утилизация
            </div>
          </div>
        </div>

        <div v-if="!isMobile" class="buttons buttons--card-full">
          <VButton
            v-if="permissionPrintDocumentProduction"
            text="На печать"
            icon="print"
            size="sm"
            @click.native="openModal('print')"
          />

          <VButton
            v-if="permissionDestroyTemplatePosition && isFirstStage"
            text="Удалить партию"
            size="sm"
            icon="remove"
            @click.native="openModal('remove')"
          />

          <VButton
            v-if="permissionUpdateConsignmentWeight"
            text="Общий вес"
            icon="create"
            size="sm"
            color="primary-sm"
            @click.native="openModal('dirty-weight')"
          />

        </div>
        <div v-else class="buttons buttons--card-full">
          <VButton
            v-if="permissionPrintDocumentProduction"
            icon="print"
            size="sm"
            @click.native="openModal('print')"
          />
          <DropdownMenu :dropdown-is-modal="isModal" @closeModal="closeModal" @openModal="openModal">
            <router-link v-if="isMobile" :to="{ name: 'OrderDetails', params: { id: consignment.order.id } }">
              <VButton
                text="Перейти к заказу"
                icon="info"
                size="sm"
              />
            </router-link>

            <VButton
              v-if="permissionDestroyTemplatePosition && isFirstStage"
              text="Удалить партию"
              size="sm"
              icon="remove"
              @click.native="openModal('remove')"
            />

            <VButton
              v-if="permissionUpdateConsignmentWeight"
              text="Общий вес"
              icon="create"
              size="sm"
              color="primary-sm"
              @click.native="openModal('dirty-weight')"
            />
          </DropdownMenu>
          <VButton
            block="collapse"
            :class="{ open: isOpenAccordion }"
            @click.native="collapseAccordion"
            style="z-index: 2"
            :icon="{ name: 'arrow', dir: isOpenAccordion ? '0' : '180' }"
          />
        </div>
      </header>

      <!--  details  -->
      <div class="card__body" @dblclick="closeAccordion">
        <router-link :to="{ name: 'OrderDetails', params: { id: consignment.order.id } }" :class="{ 'link-disabled': isMobile }">
          <h3 class="title-link">
            <span>Заказ №{{ orderAccountNumber }}</span>
            <span>Позиция №{{ namePosition }}</span>
          </h3>
        </router-link>

        <AccordionCard
          :is-open="!isMobile || isOpenAccordion"
          class="mt-30"
        >
          <!--   TODO ConsignmentInfo     -->
          <div class="consignment-info__list mb-30">
            <InfoTextBlock
              label-text="Кол-во"
              :text="consignment.value"
            />
            <InfoTextBlock
              v-if="consignment.probes"
              labelText="Кол-во пробников"
              :text="consignment.probes"
            />
            <InfoTextBlock
              v-if="consignment.defect"
              labelText="Кол-во брака"
              :text="consignment.defect"
            />
            <InfoTextBlock
              label-text="Этап"
              :text="consignment.status && consignment.status.name"
            />
            <InfoTextBlock
              label-text="Способ навивки"
              :text="consignment.winding_method && consignment.winding_method.name"
            />
            <InfoTextBlock
              label-text="Чистый вес"
              :text="consignment.weight_clear ? consignment.weight_clear : '-'"
            />
            <InfoTextBlock
              v-if="consignment.weight_dirty"
              label-text="Общий вес"
              :text="consignment.weight_dirty ? consignment.weight_dirty : '-'"
            />
          </div>

          <div class="consignment__person mb-30">
            <svgicon
              class="svg-icon--user-avatar mr-8"
              icon="user-avatar"
              width="25"
              height="25"
            />

            {{ getUserFullName(consignment.responsible) || '-' }}&nbsp;(Бригадир)
          </div>

          <PositionInfo
            v-if="consignment.position && !isMobile"
            :position="consignment.position"
            :type-position="consignment.position.type"
          />
          <PositionInfoMobile
            v-else-if="consignment.position && isMobile"
            :position="consignment.position"
            :type-position="consignment.position.type"
          />
        </AccordionCard>
      </div>
    </div>

    <!-- stages   -->
    <StageCardList
      class="card card__body mb-40"
      :stage-list="stageData"
    />

    <!-- buttons   -->
    <div class="buttons buttons-consignment gap-s mb-20">
      <VButton
        text="Комментарий"
        size="l"
        @click.native="selectedTab = 'Комментарий'"
      />
      <VButton
        text="Рекламация"
        color="red"
        size="l"
        :disabled="!permissionUpdateConsignmentReclamation"
        @click.native="selectedTab = 'Рекламация'"
      />
      <VButton
        v-if="permissionUpdateConsignmentStageBack && additionalVerificationPermissionUpdateConsignmentStage"
        text="Вернуть"
        color="orange"
        size="l"
        :disabled="isFirstStage"
        @click.native="openModal('stage-back')"
      />
      <!-- только при решении пробные испытания (доделать?) -->
      <VButton
        text="Утилизация"
        color="red"
        size="l"
        :disabled="!permissionUpdateConsignmentUtil || !(consignment.outer_reclamation_indicator === 5) || isScrap"
        @click.native="openModal('isUtil')"
      />

      <VButton
        v-if="permissionUpdateConsignmentStageNext && additionalVerificationPermissionUpdateConsignmentStage"
        text="Передать дальше"
        class="ml-auto"
        color="green"
        size="l"
        :disabled="isLastStage"
        @click.native="openModalSplit"
      />
    </div>

    <!--  TODO form  -->
    <!-- comments   -->
    <VForm
      class="consignment__form"
      v-show="selectedTab === 'Комментарий'"
      @request="submitComment"
      v-model="formDataComment"
    >
      <h3>Комментарий</h3>

      <VInput
        type="textarea"
        name="comment"
        id="returnOrderBatch"
        height="120px"
        class="consignment__form--comment"
        required
      >
        <Validation for="required">Поле обязательно</Validation>
      </VInput>

      <VButton
        text="Отправить"
        type="submit"
        color="primary"
        size="l"
        :loading="commentLoadingBtn"
      />
    </VForm>

    <!--  TODO form  -->
    <!-- Рекламация   -->
    <VForm
      class="consignment__form consignment__form--bg-red"
      v-show="selectedTab === 'Рекламация'"
      @request="submitReclamation"
      v-model="formDataReclamation"
      ref="formReclamation"
    >
      <h3>Рекламация</h3>

      <div class="consignment__form-grid">
        <VSelect
          v-if="consignmentReclamationInnerOptions.length"
          label="Изменить рекламацию"
          name="updateReclamation"
          placeholder="Выбрать"
          :options="consignmentReclamationInnerOptions"
          item-text="name"
          @change="updateReclamation"
        />

        <VSelect
          label="Принятое решение"
          name="type"
          placeholder="Выбрать"
          :options="reclamationInnerTypeList"
          item-text="name"
          :custom-validation="checkReclamationType"
        >
          <Validation for="customValidation">Тип рекламации не соответствует количеству пружин</Validation>
        </VSelect>

        <VSelect
          label="Причина рекламации"
          name="reclamation"
          placeholder="Выбрать"
          :options="reclamationInnerList"
          required
          item-text="name"
        >
          <Validation for="required">Поле обязательно</Validation>
        </VSelect>

        <VSelect
          label="Ответственный за несоответствие"
          name="responsible"
          :options="reclamationUserList"
          required
        >
          <Validation for="required"/>
        </VSelect>

        <VInput
          label="Кол-во пружин"
          name="springs"
          required
          :pattern="/^\d+$/"
          :max-value="consignment.value"
        >
          <Validation for="required">Поле обязательно</Validation>
          <Validation for="pattern">Только цифры</Validation>
          <Validation for="maxValue">Кол-во пружин не может быть больше {{ consignment.value }}</Validation>
        </VInput>

        <VSelect
          v-if="visibleReclamationStage"
          label="Отправить на этап"
          name="stage"
          placeholder="Выбрать"
          :options="reclamationStageOptions"
          item-text="status"
          :custom-validation="checkReclamationStage"
        >
          <Validation for="customValidation">Необходимо выбрать этап</Validation>
        </VSelect>

        <VInput
          label="Комментарий"
          type="textarea"
          name="comment"
          required
        >
          <Validation for="required">Поле обязательно</Validation>
        </VInput>
      </div>

      <VButton
        text="Отправить"
        type="submit"
        color="primary"
        size="l"
        :loading="loadingReclamation"
      />
    </VForm>

    <div class="comment mt-40">
      <h2 class="mb-30">
        Комментарии
      </h2>

      <div v-if="comments.length" class="d-flex flex-col gap-s">
        <CardComment
          v-for="item in comments"
          :key="item.id"
          :comment="item"
        />
      </div>

      <h3 v-else>Комментариев пока нет</h3>
    </div>

    <ModalConsignmentPrint
      v-if="isModal === 'print'"
      :consignment-id="consignment.id"
      :is-inner-reclamation="consignment.is_inner_reclamation"
      @close="closeModal"
      @request="openModalPreview"
    />

    <ModalConsignmentRemove
      v-if="isModal === 'remove'"
      :consignment-id="consignment.id"
      @remove="removeConsignmentHandler"
      @reject="closeModal"
    />

    <ModalConsignmentWeight
      v-if="isModal === 'dirty-weight'"
      title="Общий вес"
      :consignment="consignment"
      @addDirtyWeight="addDirtyWeightHandler"
      @reject="closeModal"
    />

    <ModalConfirmation
      v-if="isModal === 'stage-back'"
      title="Вы действительно хотите вернуть партию на предыдущий этап?"
      text-btn-reject="Отмена"
      @confirm="goToStageBack(consignment.id)"
      @reject="closeModal"
    />

    <ModalConfirmation
      v-if="isModal === 'isUtil'"
      title="Партия будет утилизирована, и создана копия текущей Позиции. Продолжить дальше?"
      text-btn-reject="Отмена"
      @confirm="openModal('util')"
      @reject="closeModal"
    />

    <ModalConfirmationUtil
      v-if="isModal === 'util'"
      title="Утилизация"
      :consignment="consignment"
      @utilization="utilizationHandler"
      @reject="closeModal"
    />

    <ModalConsignmentSplit
      v-if="isModal === 'stage-next'"
      :consignment="consignment"
      :isFirstStage="isFirstStage"
      :stage-line="modalStageNext.stageLine"
      @goStageNext="goStageNext"
      @goSplit="goSplit"
      @reject="closeModal"
    />

    <ModalConsignmentSplitPrint
      v-if="isModal === 'split-print'"
      @goSplitPrint="closeModalPrintSplit"
      :consignment-id="consignment.id"
      :consignment-split-id="getSplitConsignmentId"
      @reject="closeModal"
      @request="openModalPreview"
    />

    <ModalFilesPreview
      v-if="isModal === 'files_preview'"
      :files="files"
      @close="closeModalPreview"
    />

  </div>
</template>

<script>
import CardComment from '@/components/production/CardComment'
import VForm from '@/components/Form/VForm'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import ModalConsignmentPrint from '@/components/ui/Modal/Production/Consignment/ModalConsignmentPrint'
import ModalConsignmentRemove from '@/components/ui/Modal/Production/Consignment/ModalConsignmentRemove'
import ModalConsignmentWeight from '@/components/ui/Modal/Production/Consignment/ModalConsignmentWeight'
import ModalConfirmationUtil from '@/components/ui/Modal/Production/Consignment/ModalConfirmationUtil'
import PositionInfo from '@/components/production/position/PositionInfo'
import PositionInfoMobile from '@/components/production/position/PositionInfoMobile'
import StageCardList from '@/components/production/stage/StageCardList'
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinConsignment } from '@/mixins/views/Production/Consignment/mixinConsignment'

import {
  CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST
} from '@/const/production/consignment/consignmentReclamationInnerTypeList'

import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { instance } from '@/api'

import { create, update, getAll } from '@/api/request'
import { dateFilter } from '@/filters/date.filter'
import { goToPage, goToPrevPage } from '@/utils/router'
import { setAlertError, setAlertSuccess } from '@/utils/store/alert'
import { getUserFullName, getUserItem } from '@/utils/model/User'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'
import ModalConsignmentSplitPrint from '@/components/ui/Modal/Production/Consignment/ModalConsignmentSplitPrint.vue'
import ModalConsignmentSplit from '@/components/ui/Modal/Production/Consignment/ModalConsignmentSplit.vue'
import ModalFilesPreview from '@/components/files/ModalFilesPreview.vue'
import DropdownMenu from '@/components/ui/DropdownMenu.vue'
import AccordionCard from '@/components/accordionList/AccordionCard.vue'
import { mixinAccordion } from '@/mixins/mixinAccordion'

export default {
  name: 'DetailedConsignment',
  components: {
    AccordionCard,
    DropdownMenu,
    ModalFilesPreview,
    ModalConsignmentSplit,
    ModalConsignmentSplitPrint,
    ModalConsignmentRemove,
    CardComment,
    VForm,
    InfoTextBlock,
    ModalConfirmation,
    ModalConsignmentPrint,
    ModalConsignmentWeight,
    PositionInfo,
    PositionInfoMobile,
    StageCardList,
    Validation,
    VButton,
    VButtonPageBack,
    VInput,
    VSelect,
    ModalConfirmationUtil
  },
  mixins: [
    mixinModal,
    mixinConsignment,
    mixinAccordion
  ],
  props: {
    id_cons: {
      type: [String, Number],
      default: null
    }
  },

  data: () => ({
    loading: false,
    loadingStage: false,
    loadingReclamation: false,

    stageData: [],
    commentLoadingBtn: false,
    comments: [],

    selectedTab: 'Комментарий',
    formDataComment: {
      comment: ''
    },

    urlReclamationInner: 'consignment/inner-reclamations',
    reclamationInnerTypeList: CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST,
    formDataReclamation: {},

    modalStageNext: {
      stageLine: []
    },
    consignmentSplit: [],

    // preview files print
    files: []
  }),

  computed: {
    ...mapGetters({
      consignment: 'consignment/consignment',
      consignmentReclamationInnerList: 'consignment/consignmentReclamationInnerList',
      currentUserId: 'currentUserId',

      // внутренняя рекламация
      reclamationInnerList: 'handbook/reclamationInnerReason/getList'
    }),
    ...mapGetters('permission', {
      permissionPrintDocumentProduction: 'permissionPrintDocumentProduction',
      permissionUpdateConsignmentStageNext: 'permissionUpdateConsignmentStageNext',
      permissionUpdateConsignmentStageBack: 'permissionUpdateConsignmentStageBack',
      permissionUpdateConsignmentWeight: 'permissionUpdateConsignmentWeight',
      permissionDestroyTemplatePosition: 'permissionDestroyTemplatePosition',
      permissionUpdateConsignmentReclamation: 'permissionUpdateConsignmentReclamation',
      permissionUpdateConsignmentUtil: 'permissionUpdateConsignmentUtil'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    orderAccountNumber () {
      return this.consignment?.order?.account_number || ''
    },
    namePosition () {
      return `${this.consignment.position?.sort} ${this.consignment.position?.type?.name} ${this.consignment.position?.value}`
    },

    isLastStage () {
      const statusLine = this.consignment.statuses_line?.statuses
      if (!statusLine) return true
      const id = statusLine[statusLine.length - 1].status_id
      return this.consignment.status.id === id
    },
    isFirstStage () {
      return this.consignment?.status?.id === this.consignment?.statuses_line?.statuses[0]?.status_id
    },

    hasDirtyWeight () {
      if (this.consignment?.warehouse) return true
      else return !!this.consignment?.weight_dirty
    },

    reclamationUserList () {
      const list = []
      const filterIds = []

      const techManager = this.consignment?.order?.tech_manager
      if (techManager) {
        list.push(getUserItem(techManager))
        filterIds.push(techManager.id)
      }

      const productManager = this.consignment?.position?.responsible
      if (productManager) {
        list.push(getUserItem(productManager))
        filterIds.push(productManager.id)
      }

      if (!this.stageData.length) return list

      this.stageData.forEach(item => {
        if (item.responsible && !filterIds.includes(item.responsible.id)) {
          filterIds.push(item.responsible.id)
          list.push(item.responsible)
        }
        if (item.executor && !filterIds.includes(item.executor.id)) {
          filterIds.push(item.executor.id)
          list.push(item.executor)
        }
      })

      return list
    },
    isConsignmentReclamation () {
      return this.consignment.inner_reclamations?.length
    },
    isScrap () {
      return this.consignment.is_scrap || false
    },
    reclamationStageOptions () {
      if (!this.stageData.length) return []

      return this.stageData.slice(0, -3)
    },
    consignmentReclamationInnerOptions () {
      if (!this.consignmentReclamationInnerList?.length) return []

      return this.consignmentReclamationInnerList.map(itemConsignmentReclamation => {
        const date = dateFilter(itemConsignmentReclamation.updated_at)
        const reclamationName = this.reclamationInnerList
          .find(itemReclamation => itemReclamation.id === itemConsignmentReclamation.reason_id)?.name

        return {
          ...itemConsignmentReclamation,
          name: `${date} ${reclamationName} #${itemConsignmentReclamation.id}`
        }
      })
    },
    visibleReclamationStage () {
      switch (this.formDataReclamation?.type?.value) {
        case 'rework': return true
        default: return false
      }
    },

    getSplitConsignmentId () {
      return this.consignmentSplit?.id || null
    }
  },

  async mounted () {
    const id = parseInt(this.id_cons)

    await this.fetchConsignmentData(id)

    if (this.permissionUpdateConsignmentReclamation) {
      await this.$store.dispatch('handbook/reclamationInnerReason/loadList')
    }
  },

  beforeDestroy () {
    this.comments = []
  },

  methods: {
    ...mapMutations({
      setScrollNodeId: 'scroll/SET_NODE_ID'
    }),
    ...mapActions('consignment', {
      getOneConsignment: 'getConsignment',
      updateConsignment: 'updateConsignment'
    }),
    getUserFullName,
    goToPage,

    async fetchConsignmentData (id) {
      try {
        this.loadingStage = true

        await Promise.all([
          this.getOneConsignment(id),
          this.getStageData(id),
          this.getCommentAllByIdToConsignment(id)
        ])
      } finally {
        this.loadingStage = false
      }
    },
    async addDirtyWeightHandler (data) {
      this.closeModal()
      this.consignment.weight_dirty = data.weight_dirty
    },
    async utilizationHandler (data) {
      this.closeModal()
      this.consignment.is_scrap = data.is_scrap
      this.consignment.status = data.status
      this.consignment.responsible = data.responsible
    },

    async removeConsignmentHandler () {
      this.closeModal()
      await goToPage('OrderDetails', { id: this.consignment.order.id })
    },

    async submitComment (data) {
      this.commentLoadingBtn = true

      const postData = {
        user_id: this.currentUserId,
        consignment_id: this.id_cons,
        ...data,
        type: 1
      }

      try {
        const res = await instance.post('/consignment/comments', postData)
        this.comments.unshift(res.data)
        this.formDataComment.comment = ''
      } catch (e) {
        await setAlertError(e, 'Не удалось создать комментарий')
      } finally {
        this.commentLoadingBtn = false
      }
    },

    async submitReclamation (data) {
      const reclamationData = {
        responsible_id: data.responsible.id,
        reason_id: data.reclamation.id,
        comment: data.comment,
        springs: +data.springs,
        consignment_id: this.consignment.id,
        type: data.type?.value || null,
        weight: this.consignment?.weight_dirty / this.consignment?.value * +data.springs || 0
      }

      if (data.type?.value === 'rework') {
        reclamationData.status_id = data.stage.id
      }

      let textSuccess = 'создана'
      let textError = 'создать'

      try {
        this.loadingReclamation = true
        if (data.updateReclamation?.id) {
          textSuccess = 'обновлена'
          textError = 'обновить'
          await update(this.urlReclamationInner, data.updateReclamation.id, reclamationData)
        } else {
          await create(this.urlReclamationInner, reclamationData)
        }

        this.$refs.formReclamation.clear()

        await setAlertSuccess('Рекламация успешно ' + textSuccess)

        const id = parseInt(this.id_cons)

        await this.fetchConsignmentData(id)
      } catch (e) {
        await setAlertError(e, `Не удалось ${textError} рекламацию`)
      } finally {
        this.loadingReclamation = false
        this.loadingStage = false
      }
    },

    async getCommentAllByIdToConsignment (id) {
      try {
        const params = {
          consignment_id: id,
          sort: '-id'
        }

        const res = await getAll('consignment/comments', params)
        this.comments = [...res.data]
      } catch (e) {
        await setAlertError(e, 'Не удалось загрузить список комментариев')
      }
    },

    async getStageData (id) {
      const response = await ConsignmentController.getStageLine(id)
      if (response?.status !== 200) return

      this.modalStageNext.stageLine = response.data

      this.stageData = response.data.map(item => {
        return {
          name: `Этап ${item.sort}`,
          status: `${item.status?.name}`,
          flag_id: item.status?.flag_id,
          id: item.status.id,
          responsible: getUserItem(item.user),
          executor: getUserItem(item.executor)
        }
      })
    },

    async goStageNext (data) {
      this.closeModal()

      this.$store.commit('consignment/SET_CONSIGNMENT', data)
      this.$store.commit('orderDetail/UPDATE_CONSIGNMENT', data)

      await this.getOneConsignment(this.id_cons)
    },

    async goToStageBack (id) {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        const data = {
          changer_id: this.currentUserId
        }
        const response = await ConsignmentController.goStageBack(id, data)
        if (response?.status !== 200) return

        this.$store.commit('consignment/SET_CONSIGNMENT', response.data)
        this.$store.commit('orderDetail/UPDATE_CONSIGNMENT', response.data)
        await this.getOneConsignment(this.id_cons)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModal()
      }
    },

    updateReclamation (reclamationData) {
      if (!reclamationData?.id) {
        this.$refs.formReclamation.clear()
        return
      }

      let type = null
      switch (reclamationData.type) {
        case 'remake': type = this.reclamationInnerTypeList[0]
          break
        case 'partial-remake': type = this.reclamationInnerTypeList[1]
          break
        case 'rework': type = this.reclamationInnerTypeList[2]
          break
        case 'deviation_agreed_client': type = this.reclamationInnerTypeList[3]
          break
        case 'shipment_no_agreement': type = this.reclamationInnerTypeList[4]
      }
      const reclamationReason = this.reclamationInnerList.find(item => item.id === reclamationData.reason_id)
      const responsible = this.reclamationUserList.find(item => item.id === reclamationData.responsible_id)
      const stage = this.reclamationStageOptions.find(item => item.id === reclamationData.status_id)

      Vue.set(this.$refs.formReclamation.formData, 'type', type)
      Vue.set(this.$refs.formReclamation.formData, 'reclamation', reclamationReason)
      Vue.set(this.$refs.formReclamation.formData, 'responsible', responsible)
      Vue.set(this.$refs.formReclamation.formData, 'springs', reclamationData.springs)
      Vue.set(this.$refs.formReclamation.formData, 'stage', stage)
      Vue.set(this.$refs.formReclamation.formData, 'comment', null)
    },
    // валидация рекламации
    checkReclamationStage (value) {
      return !(this.formDataReclamation?.type?.value === 'rework' && !value)
    },
    checkReclamationType (type) {
      if (type?.value === 'remake') {
        if (!this.formDataReclamation?.springs) return false
        if (+this.formDataReclamation?.springs !== this.consignment.value) return false
      }

      if (type?.value === 'partial-remake') {
        if (!this.formDataReclamation?.springs) return false
        if (+this.formDataReclamation?.springs === this.consignment.value) return false
      }

      return true
    },

    onPageBack () {
      this.setScrollNodeId(`consignment-card--${this.consignment.id}`)
      goToPrevPage()
    },

    async goSplit (data) {
      this.consignmentSplit = data
      this.$emit('goSplit', data)
      const id = parseInt(this.id_cons)

      await this.fetchConsignmentData(id)

      this.closeModalSplit()
      this.openModal('split-print')
    },

    goSplitPrint () {
      this.$emit('goSplitPrint', this.consignmentSplit)
    },

    closeModalPrintSplit () {
      this.$emit('goSplitPrint', this.consignmentSplit)
      this.closeModal()
    },

    closeModalSplit () {
      this.closeModal()
    },

    openModalSplit () {
      if (!this.isConsignmentReclamation || (this.isConsignmentReclamation && !this.consignment.inner_reclamations[0].type === 'rework')) {
        this.modalStageNext.stageLine = []
      }
      this.openModal('stage-next')
    },

    openModalPreview (file) {
      this.files.length = 0
      this.files.push(file)
      this.openModal('files_preview')
    },

    closeModalPreview () {
      this.files = []
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 20px;
  box-shadow: $color__card-box-shadow;
  background: var(--color__lavander);

  .card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    border-bottom: 1px solid $color__card-inner-border;

    @media screen and (max-width: $tablet-width-s) {
      padding: var(--xs-mobile) var(--xs-mobile) 0;
      border-bottom: none;
      margin-bottom: var(--s);
    }
    @media screen and (max-width: $mobile-width) {
      align-items: start;
      &-wrap {
        flex-direction: column;
        align-items: normal;
      }
    }
  }

  .card__title {
    text-transform: uppercase;

    @media screen and (max-width: $tablet-width-s) {
      @include font18;
    }
  }

  &__body {
    padding: 30px 40px;

    @media screen and (max-width: $tablet-width-s) {
      padding: 0 var(--xs-mobile) var(--xs-mobile);

      & .title-link {
        @include font18;
        gap: 0;
      }
    }
  }
}

.consignment-info {
  &__list {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-width-s) {
      flex-direction: column;
      gap: var(--s);
    }
  }
}

.consignment {
  &__person {
    display: flex;
    align-items: center;
  }

  &__body .h3 {
    cursor: pointer;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
    padding: 30px 30px 40px;
    background-color: var(--color__lavander);

    &--bg-red {
      background-color: $color__red--light;
    }

    &--comment {
      max-width: 460px;
    }

    @media screen and (max-width: $tablet-width-s) {
      padding: var(--xs-mobile);

      &--comment {
        max-width: 100%;
      }
    }
  }

  &__form-grid {
    display: grid;
    gap: 16px;
    max-width: var(--max-w-input);
  }
}
</style>
